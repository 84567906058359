import React from "react";
import { Link } from "gatsby"
import Icon from "../atoms/Icon"
import "../../assets/styles/atoms/button.scss"
const Button = ({children, type, color, callBack, url, label, callBackHover, isSelected}) => {


    if(type === "main" || type === "menu"){

        if(url){
            return (
                <Link tabIndex="-1" to={url} className={`EMI-main-button ${color} ${type}`}>
                { children }
                </Link>
            ) 
        }
        return (
            <button tabIndex="-1" className={`EMI-main-button ${color} ${type} EMI-button`} onClick={(e) => {callBack(e)}}>
                    { children }
            </button>
        )

    } else if (type === "menuLink" ) {
        if(callBackHover){

            return (
                <Link 
                onMouseEnter={(event) => callBackHover ? callBackHover(event, true) : ""} 
                onMouseLeave={(event) => callBackHover ? callBackHover(event, false) : ""} 
                className={`EMI-menu-button ${color} ${type}`} 
                to={url}
                >
                <span>{ children }</span>
                <span>{label}</span>
            </Link>
        )
        } else {
            return (
                <Link
                    className={`EMI-menu-button ${color} ${type}`} 
                    to={url}
                >
                    <span>{ children }</span>
                    <span>{label}</span>
                </Link>
            )
        }
    } else if (type==="arrow-btn"){
        if(url){
            return <Link tabIndex="-1" className={`EMI-arrow-button ${color} ${type}`} to={url}>
                <span>{ children }</span>
                <span><Icon type="arrow"/></span>
            </Link>

        }
        return (
            <button tabIndex="-1" className={`EMI-arrow-button ${color} ${type}`} onClick={(e) => {callBack(e)}}>
                <span>{ children }</span>
                <span><Icon type="arrow"/></span>
            </button>
        )
    } else if (type === "size-btn"){
        return (
            <button tabindex="-1" className={`EMI-size-button ${color} ${type} ${isSelected ? "selected" : ""}`} onClick={(e) => {callBack(e)}}>
                {children}
            </button>
        )
    } else if (type === "icon") {
        return <button tabIndex="-1" onClick={() => callBack()} className="EMI-icons">
            {children}
        </button>
    }else if (type === "selector"){
        return <button tabIndex="-1" onClick={() => callBack()}  className={`EMI-Selector ${isSelected ? "selected" : ''}`}></button>
    }
}

export default Button