exports.components = {
  "component---src-components-templates-boutique-js": () => import("./../../../src/components/templates/boutique.js" /* webpackChunkName: "component---src-components-templates-boutique-js" */),
  "component---src-components-templates-evenements-js": () => import("./../../../src/components/templates/evenements.js" /* webpackChunkName: "component---src-components-templates-evenements-js" */),
  "component---src-components-templates-galerie-js": () => import("./../../../src/components/templates/galerie.js" /* webpackChunkName: "component---src-components-templates-galerie-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cours-js": () => import("./../../../src/pages/cours.js" /* webpackChunkName: "component---src-pages-cours-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscription-js": () => import("./../../../src/pages/inscription.js" /* webpackChunkName: "component---src-pages-inscription-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */)
}

