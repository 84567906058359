import React from "react";
import eventBus from "../../assets/scripts/utils/eventBus";
import Menu from "../molecules/Menu"
import "../../assets/styles/utils/root.scss"
import useSmoothScroll from "../../assets/scripts/hooks/useSmoothScroll";
import Footer from "../sections/Footer";
import { Link } from "gatsby";
// import Noise from "../atoms/Noise"


const PageLayout = ({ children, isHorizontal, isWebGL}) => {
    const [backgroundColor, setBackgroundColor ] = React.useState("white")
    const [direction, setDirection] = React.useState("")
    const [firstClick, setFirstClick] = React.useState("")
    const [seccondtClick, setSeccondClick] = React.useState("")
    const [isClicked, setIsClick] = React.useState("")
    const [w, setW] = React.useState()
    const [path, setPath ] = React.useState("") 

    const scroll = useSmoothScroll("vertical");


    const handleMouseOut = (event) => {

        if (event.relatedTarget === null) {
            setIsClick(false)
        }
    }

    React.useEffect(() => {

        if (scroll) {

            scroll.start()



            scroll.on("scroll", (e) => {
                if(e.direction > 0){
                    document.querySelector(".inscription_link").style.transform = `translateY(-100px)`
                    if(document.querySelector("#scroll")) 
                        document.querySelector("#scroll").style.opacity = 0 

                }else {
                    if(e.targetScroll < 100){
                        document.querySelector(".inscription_link").style.transform = `translateY(0)`
                        if(document.querySelector("#scroll"))
                            document.querySelector("#scroll").style.opacity = 1 
                        
                    }
                }


                if(window.location.pathname === "/" && e.targetScroll < window.innerHeight){
                    document.querySelector(".EMI-main-button.menu").classList.add("black")
                    document.querySelector(".EMI-main-button.menu").classList.remove("white")
                    
                } else {
                 document.querySelector(".EMI-main-button.menu").classList.add("white")
                    document.querySelector(".EMI-main-button.menu").classList.remove("black")
                }
            })





            
        }

        
    }, [scroll]);
    
    React.useEffect(() => {
        const {x: firstX, y: firstY } = firstClick
        const {x: seccondX, y: seccondY } = seccondtClick

        const diffX = seccondX - firstX
        const diffY = seccondY - firstY

        const diff = diffX + diffY
        window.lenis.scrollTo(window.lenis.targetScroll - 1.25 * diff)
        

    }, [seccondtClick])



    React.useEffect(() => {
        setPath(window.location.pathname)
        if(window.location.pathname === "/" ){
            document.querySelector(".EMI-main-button.menu").classList.add("black")
            document.querySelector(".EMI-main-button.menu").classList.remove("white")

        }
        eventBus.on("changeColor", (e) => {
            
            setBackgroundColor(e.color)
            
        })
        setW(window)

        eventBus.on("routeChange", () => {
            
            window.lenis.scrollTo(0, {immediate : true})
            setPath(window.location.pathname)

        })

        eventBus.on("changeDirection", (e) => {

            window.lenis.options.orientation = window.innerWidth > 768 ? e.direction : "vertical"
            setDirection("vertical")
            
        })

        window.addEventListener('mouseout', handleMouseOut);


        return () => window.removeEventListener("mouseout", handleMouseOut)

    }, [])


    return (<>
        <script defer type="text/javascript" src="https://api.pirsch.io/pirsch.js"
        id="pirschjs"
        data-code="X2tPOWpAHLOpHa6apZhBX5E96JP6yXwS"></script>
        <main 
        onMouseDown={(e) => {
            if(window.innerWidth > 767){

                setIsClick(true)
                setFirstClick({x: e.clientX, y: e.clientY})

            }
        }}
        onMouseMove={(e) => {
            if(isClicked){
                setSeccondClick({x: e.clientX, y: e.clientY})
            }
        }}
        onMouseUp={e => {
            setIsClick(false)
        }}
        >
            <div className="container">
            {children}
            </div>
            {/* <Noise/> */}
            <Menu/>
            <Link to="/inscription" className={"inscription_link EMI-main-button menu " + " " + `${path==="/" ? "black" : "white"}` }>Inscription</Link>
            <Footer type={direction}/>

        </main>

    </>)
}
export default PageLayout