const React = require("react");
const LayoutPage = require("./src/components/layouts/PageLayout.jsx").default;
const eventBus = require("./src/assets/scripts/utils/eventBus.js").default;

exports.wrapPageElement = ({ element, props }) => {
  return <LayoutPage {...props}>{element}</LayoutPage>;
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  eventBus.dispatch("routeChange", location);
};

exports.shouldUpdateScroll = () => {
  return false;
};
