import React from "react"
import Button from "../atoms/Button"
import "../../assets/styles/molecules/menu.scss"
import eventBus from "../../assets/scripts/utils/eventBus"


const Menu = () => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const [isDeferedMenuOpen, setIsDeferedMenuOpen] = React.useState(false)
    const [backgroundColor, setBackgroundColor ] = React.useState("#ffffff")
    const [buttonColor, setbuttonColor ] = React.useState("white")
    const [isMobile, setIsMobile ] = React.useState(false)

    const handleHover = (e, isEnter) => {
        if(!isMobile){

            const menuLinks = document.querySelectorAll(".EMI-menu-button");
            menuLinks.forEach(link => {
                if(isEnter){
                    
                    if(e.target.parentNode !== link){
                        link.style.filter = "blur(8px)"
                    }
                }else{
                    link.style.filter = "blur(0px)"
                    
                }
            })
        }
    }


    React.useEffect(()=>{
            if(isMenuOpen){
                // window.lenis.stop()
                setIsDeferedMenuOpen(true)
                setbuttonColor("black")
                
            }else{
                // window.lenis.start()
                

                const menuFragments = document.querySelectorAll(".EMI-menu-pannel");
                const links = document.querySelectorAll(".EMI-menu-pannel .EMI-menu-button");
    
                if(menuFragments.length > 0 ){

                
                    menuFragments[0].style.transform = `translateY(${isMobile ? '1' : '2'}00%)`
                    menuFragments[0].style.transitionDelay = "0.5s"
                    
                    if(!isMobile){

                        menuFragments[1].style.transform = "translateY(0%)"
                        menuFragments[1].style.transitionDelay = "0.5s"
                    
                    }


                    links.forEach((link, index) => {
                        link.querySelector("span:nth-child(1)").style.transform = "translateY(80%)"
                        link.querySelector("span:nth-child(1)").style.opacity = "0"
                        link.querySelector("span:nth-child(1)").style.transitionDelay = `0s`
                        
                        link.querySelector("span:nth-child(2)").style.transform = "translateY(100%)"
                        link.querySelector("span:nth-child(2)").style.opacity = "0"
                        link.querySelector("span:nth-child(2)").style.transitionDelay = `0s`
                    })
                    // setbuttonColor("white")
                       setbuttonColor(window.location.pathname === "/" && window.lenis.targetScroll < window.innerHeight ? "black" : "white")

                }

                setTimeout( () => {setIsDeferedMenuOpen(false)} , 1000)
                
            }
    }, [isMenuOpen])
    
    React.useEffect(()=>{

            const menuFragments = document.querySelectorAll(".EMI-menu-pannel");
            const links = document.querySelectorAll(".EMI-menu-pannel .EMI-menu-button");
            if(isDeferedMenuOpen){

                setTimeout(()=>{
                
                    menuFragments[0].style.transform = `translateY(${isMobile ? 0 : 100}%)`
                    if(!isMobile) menuFragments[1].style.transform = "translateY(-100%)"
                    
                    links.forEach((link, index) => {
                        link.querySelector("span:nth-child(1)").style.transform = "translateY(0)"
                        link.querySelector("span:nth-child(1)").style.opacity = "1"
                        link.querySelector("span:nth-child(1)").style.transitionDelay = `${index * 0.2}s`
                        
                        link.querySelector("span:nth-child(2)").style.transform = "translateY(0)"
                        link.querySelector("span:nth-child(2)").style.opacity = "1"
                        link.querySelector("span:nth-child(2)").style.transitionDelay = `${index * 0.2}s`
                    })


                }, 100)
            }
        
    }, [isDeferedMenuOpen])


    React.useEffect(()=>{
        eventBus.on("changeColor", (e) => {
            
            setBackgroundColor(e.color)
            
        })

        eventBus.on("routeChange", (e) => {
            
            setIsMenuOpen(false)
            
        })

        setIsMobile(window.innerWidth <= 678)
    }, [])
    




    return <>
        <Button type="menu" color={buttonColor} callBack={() => setIsMenuOpen(!isMenuOpen)}>
            { !isMenuOpen ? "MENU" : "FERMER" }
        </Button>
        { (!isMobile && isDeferedMenuOpen) ? <div  className={"EMI-menu " + backgroundColor }>
            <div className="EMI-menu-pannel" >

                <Button callBackHover={!isMobile ? handleHover : ""} type="menuLink" url="/" label="천만에">ACCUEIL</Button>
                <Button callBackHover={!isMobile ? handleHover : ""} type="menuLink" url="/cours" label="있습니다">COURS</Button>
                <Button callBackHover={!isMobile ? handleHover : ""} type="menuLink" url="/evenements" label="이벤트">ÉVÉNEMENTS</Button>

            </div>
            <div className="EMI-menu-pannel">

                <Button callBackHover={!isMobile ? handleHover : ""} type="menuLink" url="/boutique" label="쇼핑">BOUTIQUE</Button>
                <Button callBackHover={!isMobile ? handleHover : ""} type="menuLink" url="/galerie" label="갤러리">GALERIE</Button>
                <Button callBackHover={!isMobile ? handleHover : ""} type="menuLink" url="/contact" label="연락처">CONTACT</Button>

            </div>
        </div> : <></>
        }
        {(isMobile && isDeferedMenuOpen) && <div  className={"EMI-menu " + backgroundColor }>
            <div className="EMI-menu-pannel mobile">
                <Button type="menuLink" url="/" label="소개">ACCUEIL</Button>
                <Button type="menuLink" url="/cours" label="운동시간">COURS</Button>
                <Button type="menuLink" url="/evenements" label="이벤트">ÉVÉNEMENTS</Button>
                <Button type="menuLink" url="/boutique" label="쇼핑">BOUTIQUE</Button>
                <Button type="menuLink" url="/galerie" label="갤러리">GALERIE</Button>
                <Button type="menuLink" url="/contact" label="연락처">CONTACT</Button>
            </div>
        </div>
        
        }
    </>
}

export default Menu