import React from "react";

const Iccon = ({ type }) => {
    const [isDesktop, setIsDesktop] = React.useState(false)
    React.useEffect(() => {
        setIsDesktop(window.innerWidth > 768)

        window.addEventListener("resize", () => { setIsDesktop(window.innerWidth > 768) })
    }, [])

    switch (type) {
        case "1":
            return (<>
                {
                    isDesktop ? (
                        <svg width="82" height="215" viewBox="0 0 82 215" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M49.0398 0.859985C49.0398 19.5098 27.4774 23.0621 16.6962 22.2692H1V57.9512H16.6962H39.527V175.94V214.001H81.234V181.377V0.859985H49.0398Z" fill="#222222" stroke="#222222"/>
                        </svg>

                    ) : (
                        <svg width="42" height="108" viewBox="0 0 42 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.9499 1C24.9499 10.2977 14.2001 12.0687 8.8252 11.6734H1V29.4624H8.8252H20.2073V88.2846V107.26H41V90.9953V1H24.9499Z" fill="#222222" stroke="#222222"/>
                        </svg>
                    )
                }
            </>
            )

        case "2":
            return (<>
                {
                    isDesktop ? (
                        <svg width="132" height="216" viewBox="0 0 132 216" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.1919 68.5099H69.6031C74.7307 44.3275 91.5118 39.6769 104.564 41.072C117.176 42.4202 124.763 51.1481 126.939 56.4186C129.114 61.6892 131.227 75.0205 122.277 86.1817C111.09 100.133 93.3744 111.301 74.7307 121.221C32.3104 143.792 -1.6356 193.608 1.16127 215H131V176.866H74.7307C83.3077 158.636 103.787 146.018 112.954 141.987C119.612 138.667 125.605 135.274 131 131.852V5.04309C123.369 2.84104 114.606 1.43366 104.564 1.07796C41.5413 -1.15427 22.0564 45.1025 20.1919 68.5099Z" fill="#222222" stroke="#222222"/>
                        </svg>
                    ) : (
                        <svg width="67" height="108" viewBox="0 0 67 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5959 34.255H35.3016C37.8654 22.1638 46.2559 19.8384 52.7819 20.536C59.0882 21.2101 62.8817 25.574 63.9694 28.2093C65.057 30.8446 66.1136 37.5103 61.6386 43.0908C56.0449 50.0666 47.1872 55.6507 37.8653 60.6106C16.6552 71.8962 -0.317798 96.8039 1.08063 107.5H66V88.433H37.8653C42.1539 79.3181 52.3935 73.0089 56.9772 70.9937C60.3059 69.3333 63.3027 67.637 66 65.9258V2.52154C62.1847 1.42052 57.8031 0.716829 52.7819 0.538982C21.2706 -0.577133 11.5282 22.5513 10.5959 34.255Z" fill="#222222" stroke="#222222"/>
                        </svg>
                    )
                }

            </>
            )
        case "3":
            return (<>
                {
                    isDesktop ? <svg width="102" height="220" viewBox="0 0 102 220" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M47.7884 154.681H1.5C3.92257 217.668 68.5533 222.743 100.566 217.408V178.041C62.1507 190.5 49.3746 167.659 47.7884 154.681Z" fill="#222222" stroke="#222222"/>
                    <path d="M47.7884 65.3195H1.5C3.92257 2.33265 68.5533 -2.74321 100.566 2.59221V41.959C62.1507 29.5 49.3746 52.3414 47.7884 65.3195Z" fill="#222222" stroke="#222222"/>
                    <path d="M100.999 85C89.2319 93.652 70.7165 93.5078 62.9297 92.3542V125.665C74.3504 122.896 93.0676 126.818 100.999 129.125V85Z" fill="#222222" stroke="#222222"/>
                    </svg>
                     : <svg width="51" height="110" viewBox="0 0 51 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M23.7956 77.0039H1C2.19304 108.023 34.0216 110.523 49.7868 107.895V88.5082C30.8686 94.6438 24.5767 83.3952 23.7956 77.0039Z" fill="#222222" stroke="#222222"/>
                     <path d="M23.7956 32.9961H1C2.19304 1.97707 34.0216 -0.522633 49.7868 2.10489V21.4918C30.8686 15.3562 24.5767 26.6048 23.7956 32.9961Z" fill="#222222" stroke="#222222"/>
                     <path d="M50 42.688C44.2052 46.9488 35.087 46.8778 31.2522 46.3097V62.714C36.8765 61.3505 46.0942 63.2821 50 64.4183V42.688Z" fill="#222222" stroke="#222222"/>
                     </svg>
                     
                     
                }
            </>
            );
        case "4":
            return (<>
                {
                    isDesktop ? <svg width="116" height="218" viewBox="0 0 116 218" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.059 2.47903L2.14643 66.2345C2.05066 66.3913 2 66.5716 2 66.7554V117.961C2 118.979 3.3434 119.346 3.86104 118.47L46.6451 46.0305C47.1627 45.154 48.5061 45.5211 48.5061 46.539V135.436C48.5061 135.988 48.0584 136.436 47.5061 136.436H3C2.44772 136.436 2 136.884 2 137.436V168.633C2 169.186 2.44772 169.633 3 169.633H47.5061C48.0584 169.633 48.5061 170.081 48.5061 170.633V215C48.5061 215.552 48.9539 216 49.5061 216H87.9582C88.5105 216 88.9582 215.552 88.9582 215V170.633C88.9582 170.081 89.4059 169.633 89.9582 169.633H113C113.552 169.633 114 169.186 114 168.633V137.436C114 136.884 113.552 136.436 113 136.436H89.9582C89.4059 136.436 88.9582 135.988 88.9582 135.436V3C88.9582 2.44772 88.5105 2 87.9582 2H41.9126C41.564 2 41.2406 2.1815 41.059 2.47903Z" fill="#222222" stroke="#222222" stroke-width="4" stroke-linecap="round"/>
                    </svg>                    
                     : <svg width="59" height="109" viewBox="0 0 59 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M21.0319 2.47871L2.14662 33.3946C2.05073 33.5516 2 33.7319 2 33.9159V57.0355C2 58.0531 3.34298 58.4204 3.86084 57.5443L22.977 25.2057C23.4949 24.3296 24.8378 24.6969 24.8378 25.7145V66.9615C24.8378 67.5138 24.3901 67.9615 23.8378 67.9615H3C2.44772 67.9615 2 68.4093 2 68.9615V83.25C2 83.8023 2.44772 84.25 3 84.25H23.8378C24.3901 84.25 24.8378 84.6977 24.8378 85.25V106C24.8378 106.552 25.2856 107 25.8378 107H43.7027C44.255 107 44.7027 106.552 44.7027 106V85.25C44.7027 84.6977 45.1504 84.25 45.7027 84.25H56C56.5523 84.25 57 83.8023 57 83.25V68.9615C57 68.4093 56.5523 67.9615 56 67.9615H45.7027C45.1504 67.9615 44.7027 67.5138 44.7027 66.9615V3C44.7027 2.44772 44.255 2 43.7027 2H21.8853C21.5368 2 21.2135 2.18136 21.0319 2.47871Z" fill="#222222" stroke="#222222" stroke-width="4" stroke-linecap="round"/>
                     </svg>                     
                }
            </>
            );
        case "5":
            return (<>
                {
                    isDesktop ? <svg width="130" height="218" viewBox="0 0 130 218" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.5947 2H127C127.552 2 128 2.44772 128 3V36.3754C128 36.9277 127.552 37.3754 127 37.3754H53.3479C52.8527 37.3754 52.4321 37.7377 52.3589 38.2275L45.8935 81.4547C45.7688 82.2881 46.6863 82.8869 47.4205 82.4733C85.288 61.1383 116.446 77.834 127.726 89.2941C127.907 89.4784 128 89.7202 128 89.9789V192.997C128 193.074 127.992 193.148 127.973 193.223C125.988 201.166 110.526 216.665 64.137 215.978C18.324 215.299 3.78646 175.932 2.07706 155.335C2.02931 154.759 2.48853 154.287 3.06588 154.287H41.8074C42.2538 154.287 42.6499 154.598 42.7767 155.026C52.3961 187.485 75.0182 186.153 87.4384 178.445C99.9521 170.68 104.699 152.13 103.836 136.599C102.973 121.068 93.0479 108.126 74.9247 105.106C60.8127 102.755 49.9166 114.109 45.897 120.55C45.6989 120.868 45.3475 121.055 44.9739 121.033L9.10916 118.973C8.5313 118.939 8.10223 118.424 8.1743 117.85L22.6025 2.87549C22.6652 2.37527 23.0905 2 23.5947 2Z" fill="#222222" stroke="#222222" stroke-width="4" stroke-linecap="round"/>
                    </svg>                    
                     : <svg width="65" height="108" viewBox="0 0 65 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M12.9102 2H62C62.5523 2 63 2.44771 63 3V18.1918C63 18.7441 62.5523 19.1918 62 19.1918H27.3038C26.8085 19.1918 26.3877 19.5544 26.3147 20.0444L23.4099 39.5406C23.2856 40.3745 24.192 40.9855 24.9356 40.588C42.6817 31.104 57.2412 38.8113 62.7229 44.2777C62.9056 44.4599 63 44.7042 63 44.9623V94.7613C63 94.8385 62.9923 94.9125 62.9727 94.9871C61.953 98.8637 54.4313 106.322 32.0822 105.989C10.3026 105.665 3.12346 87.211 2.08815 77.0678C2.02954 76.4935 2.48837 76.0085 3.06562 76.0085H20.8819C21.3288 76.0085 21.7229 76.3152 21.8539 76.7424C26.5727 92.1319 37.3981 91.4649 43.363 87.7492C49.4212 83.9754 51.7192 74.9602 51.3014 67.4126C50.8836 59.865 46.0788 53.5753 37.3048 52.1077C30.6615 50.9965 25.4903 56.1829 23.4206 59.3485C23.2154 59.6623 22.864 59.8514 22.4898 59.8298L5.99197 58.8783C5.41439 58.845 4.98551 58.33 5.05727 57.7559L11.9179 2.87595C11.9805 2.37553 12.4058 2 12.9102 2Z" fill="#222222" stroke="#222222" stroke-width="4" stroke-linecap="round"/>
                     </svg>
                     
                     
                     
                }
            </>
            );
        case "6":
            return (<>
                {
                    isDesktop ? <svg width="142" height="214" viewBox="0 0 142 214" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.5309 143.189C40.5309 154.302 43.507 163.373 49.4591 170.402C55.4112 177.431 62.9694 180.945 72.1337 180.945C81.1091 180.945 88.1477 177.573 93.2495 170.83C98.4458 163.991 101.044 155.157 101.044 144.329C101.044 132.266 98.1151 123.052 92.2575 116.688C86.3999 110.229 79.2196 107 70.7166 107C63.8197 107 57.7259 109.09 52.4351 113.269C44.499 119.443 40.5309 129.416 40.5309 143.189ZM97.3593 53.9987C97.3593 50.6742 96.0838 47.0173 93.5329 43.028C89.187 36.569 82.6208 33.3395 73.8343 33.3395C60.7019 33.3395 51.3486 40.7483 45.7745 55.5659C42.7512 63.7346 40.6727 75.7976 39.5389 91.755C44.5462 85.771 50.3566 81.4017 56.9701 78.6471C63.5835 75.8926 71.1417 74.5153 79.6447 74.5153C97.8789 74.5153 112.806 80.7368 124.427 93.1798C136.142 105.623 142 121.533 142 140.909C142 160.191 136.284 177.194 124.852 191.916C113.42 206.639 95.6587 214 71.5669 214C45.68 214 26.5955 203.124 14.3134 181.373C4.77112 164.371 0 142.429 0 115.549C0 99.7812 0.661344 86.9583 1.98403 77.0799C4.34597 59.5078 8.92814 44.8802 15.7305 33.1971C21.5882 23.2237 29.2409 15.1975 38.6886 9.11851C48.2309 3.0395 59.6154 0 72.8423 0C91.9268 0 107.138 4.93919 118.475 14.8176C129.812 24.601 136.19 37.6613 137.607 53.9987H97.3593Z" fill="#222222"/>
                    </svg> : <svg width="69" height="104" viewBox="0 0 69 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M19.6946 69.5872C19.6946 74.988 21.1407 79.3964 24.0329 82.8122C26.9252 86.2281 30.5978 87.9361 35.0509 87.9361C39.4122 87.9361 42.8323 86.2974 45.3114 83.02C47.8363 79.6964 49.0988 75.4035 49.0988 70.1411C49.0988 64.2787 47.6757 59.8012 44.8293 56.7084C41.983 53.5695 38.494 52 34.3623 52C31.011 52 28.0499 53.0155 25.479 55.0466C21.6228 58.047 19.6946 62.8939 19.6946 69.5872ZM47.3084 26.2423C47.3084 24.6267 46.6886 22.8495 45.4491 20.9108C43.3373 17.7719 40.1467 16.2024 35.8772 16.2024C29.496 16.2024 24.9511 19.8029 22.2425 27.004C20.7735 30.9738 19.7635 36.8362 19.2126 44.5912C21.6457 41.6831 24.4691 39.5597 27.6826 38.221C30.8962 36.8824 34.5689 36.213 38.7006 36.213C47.5609 36.213 54.8144 39.2366 60.4611 45.2836C66.1537 51.3307 69 59.0626 69 68.4794C69 77.85 66.2226 86.1127 60.6677 93.2676C55.1128 100.423 46.482 104 34.7755 104C22.1966 104 12.9232 98.7146 6.95509 88.1438C2.31836 79.8811 0 69.2179 0 56.1545C0 48.4918 0.321357 42.2601 0.964072 37.4594C2.11178 28.9197 4.33832 21.8109 7.64371 16.1332C10.49 11.2863 14.2086 7.38571 18.7994 4.43142C23.4361 1.47714 28.9681 0 35.3952 0C44.6687 0 52.0599 2.40036 57.5689 7.20107C63.0778 11.9556 66.1767 18.3027 66.8653 26.2423H47.3084Z" fill="#222222"/>
                     </svg>
                     
                     
                     
                }
            </>
            );

        case "arrow":
            return <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5365 0.209499C10.233 -0.0762006 9.75261 -0.0645999 9.46354 0.2353C9.17447 0.5352 9.18621 1.01 9.48965 1.2957L15.8147 7.2502H0.906803C0.487703 7.2502 0.147949 7.5859 0.147949 8.0002C0.147949 8.4144 0.487703 8.7502 0.906803 8.7502H15.8152L9.48965 14.705C9.18621 14.9907 9.17447 15.4654 9.46354 15.7653C9.75261 16.0652 10.233 16.0768 10.5365 15.7911L18.0429 8.7244C18.214 8.5634 18.3151 8.358 18.3464 8.1444C18.3556 8.0977 18.3604 8.0495 18.3604 8.0002C18.3604 7.9505 18.3556 7.9019 18.3462 7.8549C18.3147 7.6418 18.2136 7.4369 18.0429 7.2762L10.5365 0.209499Z" fill="#212121"/>
            </svg>
            


        default:
            return (<p>L'icon n'a pas été trouvé.</p>)

    }
}

export default Iccon