import React from "react";
import "../../assets/styles/sections/footer.scss"
import { Link } from "gatsby";
const Footer = () => {


    return <footer className="EMI-footer">
        <div>

            <h1>TAEKWONDO CLUB SEICHAMPS</h1>

            <a>Facebook</a>
            <a>Mail</a> <br/>
            <Link to="/mentions-legales">Mentions Légales</Link>


        </div>
        <div>
            <p>Réalisé par</p>
            <a href="mailto:contact@emi.studio"><h1>EMI Studio</h1></a>
            <p>에미 사진관</p>
        </div>
    </footer>

}
export default Footer